export const INIT_STAGE = 'INIT_STAGE';
export const SET_COOKIE = 'SET_COOKIE';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const AUTH_EMAIL_SUCCESS = 'AUTH_EMAIL_SUCCESS';
export const AUTH_CODE_SUCCESS = 'AUTH_CODE_SUCCESS';
export const PASSWORD_RESET_SUCCESS = 'PASSWORD_RESET_SUCCESS';
export const SIGN_OUT_SUCCESS = 'SIGN_OUT_SUCCESS';
export const SET_PROFILE = 'SET_PROFILE';
export const SET_TREE_FILE_NAME = 'SET_TREE_FILE_NAME';

export const SET_CLIENTS = 'SET_CLIENTS';
export const SET_CLIENTS_LOADING = 'SET_CLIENTS_LOADING';
export const SET_ADD_NEW_CLIENTS = 'SET_ADD_NEW_CLIENTS';

export const SET_PORTFOLIO_COMPANIES = 'SET_PORTFOLIO_COMPANIES';
export const SET_ENTITIES_LIST = 'SET_ENTITIES_LIST';
export const SET_TRANSACTION_LIST = 'SET_TRANSACTION_LIST'; 
export const SET_ASSIGNMENT_LIST = 'SET_ASSIGNMENT_LIST';
export const SET_RAW_ASSIGNMENT = 'SET_RAW_ASSIGNMENT';
export const SET_CLEAN_ADDRESS_STATUS = 'SET_CLEAN_ADDRESS_STATUS';
export const SET_NEW_COMPANY_REQUEST = 'SET_NEW_COMPANY_REQUEST';
export const SET_CLASSIFICATION_KEYWORDS = 'SET_CLASSIFICATION_KEYWORDS';
export const SET_KEYWORDS = 'SET_KEYWORDS';
export const SET_SUPER_KEYWORDS = 'SET_SUPER_KEYWORDS';
export const SET_STATE_KEYWORDS = 'SET_STATE_KEYWORDS';
export const SET_ASSETS_LIST = 'SET_ASSETS_LIST';
export const SET_CLIENT_ID = 'SET_CLIENT_ID';
export const SET_COMPANY_BUTTONS_STATUS = 'SET_COMPANY_BUTTONS_STATUS';
export const SET_COMPANY_DATA = 'SET_COMPANY_DATA';
export const UPDATE_COMPANY_DATA = 'UPDATE_COMPANY_DATA';
export const SET_ORIGINAL_COMPANY_DATA = 'SET_ORIGINAL_COMPANY_DATA'; 
export const SET_SEARCH_BAR = 'SET_SEARCH_BAR';  
export const SET_SINGLE_SEARCH_BAR = 'SET_SINGLE_SEARCH_BAR';
export const SET_PORTFOLIO_LIST = 'SET_PORTFOLIO_LIST';  
export const SET_LAW_FIRM_LIST = 'SET_LAW_FIRM_LIST';
export const SET_LENDERS_LIST = 'SET_LENDERS_LIST'; 
export const SET_ENTITY_ASSETS = 'SET_ENTITY_ASSETS';
export const SET_RETRIEVE_COMPANY_ASSETS_HOLDING = 'SET_RETRIEVE_COMPANY_ASSETS_HOLDING';
export const SET_LAWYER_LIST = 'SET_LAWYER_LIST'; 
export const SET_INVENTOR_BUTTONS = 'SET_INVENTOR_BUTTONS';
 
export const SET_PDF_FILE = 'SET_PDF_FILE';
export const SET_PDF_VIEW = 'SET_PDF_VIEW';

export const SET_FLAG = 'SET_FLAG';  
export const SET_FLAG_AUTOMATIC = 'SET_FLAG_AUTOMATIC';
export const SET_ENTITES_UPDATE_MESSAGE = 'SET_ENTITES_UPDATE_MESSAGE';
export const SET_UPLOAD_TREE_FORM = 'SET_UPLOAD_TREE_FORM';
export const SET_CORPORATE_TREE = 'SET_CORPORATE_TREE'; 
export const SET_SEARCH_HEIGHT = 'SET_SEARCH_HEIGHT';
export const SET_TREE_HEIGHT = 'SET_TREE_HEIGHT';
export const SET_CORPORATE_HTML_FILE = 'SET_CORPORATE_HTML_FILE';

export const SET_VALIDATE_COUNTER = 'SET_VALIDATE_COUNTER';
export const SET_VALIDATE_COUNTER_LOADING = 'SET_VALIDATE_COUNTER_LOADING';

export const SET_CUSTOMERS = 'SET_CUSTOMERS';
export const SET_CUSTOMERS_LOADING = 'SET_CUSTOMERS_LOADING';

export const SET_ASSETS_COUNT = 'SET_ASSETS_COUNT';
export const SET_ASSETS_COUNT_LOADING = 'SET_ASSETS_COUNT_LOADING';

export const SET_CUSTOMERS_NAME_PARTIES = 'SET_CUSTOMERS_NAME_PARTIES';
export const SET_CUSTOMERS_NAME_PARTIES_LOADING = 'SET_CUSTOMERS_NAME_PARTIES_LOADING';

export const SET_CUSTOMERS_NAME_COLLECTIONS = 'SET_CUSTOMERS_NAME_COLLECTIONS';
export const SET_CUSTOMERS_NAME_COLLECTIONS_LOADING = 'SET_CUSTOMERS_NAME_COLLECTIONS_LOADING';

export const SET_CUSTOMER_RFID_ASSETS = 'SET_CUSTOMER_RFID_ASSETS';
export const SET_CUSTOMER_RFID_ASSETS_LOADING = 'SET_CUSTOMER_RFID_ASSETS_LOADING';

export const SET_RECORD_ITEMS = 'SET_RECORD_ITEMS';
export const SET_RECORD_ITEMS_LOADING = 'SET_RECORD_ITEMS_LOADING';

export const SET_CHARTS = 'SET_CHARTS';
export const SET_CHARTS_LOADING = 'SET_CHARTS_LOADING';

export const SET_TRANSACTIONS = 'SET_TRANSACTIONS';
export const SET_TRANSACTIONS_LOADING = 'SET_TRANSACTIONS_LOADING';

export const SET_TIME_LINE = 'SET_TIME_LINE';
export const SET_TIME_LINE_LOADING = 'SET_TIME_LINE_LOADING';
export const SET_MESSAGE = 'SET_MESSAGE';
export const SET_MESSAGES_COUNT = 'SET_MESSAGES_COUNT';
export const SET_ALERTS_COUNT = 'SET_ALERTS_COUNT';

export const CHANGE_WIDTH_AND_HEIGHT = 'CHANGE_WIDTH_AND_HEIGHT';

export const SET_CURRENT_WIDGET = 'SET_CURRENT_WIDGET';

export const SET_COMMENTS = 'SET_COMMENTS';
export const SET_COMMENTS_LOADING = 'SET_COMMENTS_LOADING';

export const SET_ASSETS = 'SET_ASSETS';
export const SET_ASSETS_LOADING = 'SET_ASSETS_LOADING';

export const SET_ASSETS_OUTSOURCE = 'SET_ASSETS_OUTSOURCE';
export const SET_ASSETS_OUTSOURCE_LOADING = 'SET_ASSETS_OUTSOURCE_LOADING';
export const SET_COMPANY_TREE_OPEN = 'SET_COMPANY_TREE_OPEN';
export const RESET_COMPANY_TREE = 'RESET_COMPANY_TREE';
export const SET_TREE_OPEN = 'SET_TREE_OPEN';
export const SET_CURRENT_ASSET = 'SET_CURRENT_ASSET';
export const SET_CURRENT_ASSET_TYPE = 'SET_CURRENT_ASSET_TYPE';
export const SET_SITE_LOGO = 'SET_SITE_LOGO';

export const SET_NESTGRID_TAB = 'SET_NESTGRID_TAB';
export const SET_TIMELINE_TAB = 'SET_TIMELINE_TAB';
export const SET_CHART_TAB = 'SET_CHART_TAB';
export const SET_FIXIT_TAB = 'SET_FIXIT_TAB';
export const SET_RECORDIT_TAB = 'SET_RECORDIT_TAB';
export const SET_PDF_TAB = 'SET_PDF_TAB';
export const SET_SETTING_TAB = 'SET_SETTING_TAB';
export const SET_LAWYERS_LIST = 'SET_LAWYERS_LIST';
export const SET_DOCUMENTS_LIST = 'SET_DOCUMENTS_LIST';
export const SET_DOCUMENT_ITEMS_LOADING = 'SET_DOCUMENT_ITEMS_LOADING';
export const SET_DOCUMENT_UPDATE_ROW = 'SET_DOCUMENT_UPDATE_ROW';
export const SET_COMPANY_LOADING = 'SET_COMPANY_LOADING';
export const SET_COMPANIES_LIST = 'SET_COMPANIES_LIST';
export const SET_USERS_LIST = 'SET_USERS_LIST';
export const SET_USERS_LIST_LOADING = 'SET_USERS_LIST_LOADING';
export const SET_SEARCH_COMPANY_LOADING = 'SET_SEARCH_COMPANY_LOADING';
export const SET_SEARCH_COMPANY = 'SET_SEARCH_COMPANY';
export const SET_SUB_COMPANIES = 'SET_SUB_COMPANIES';
export const SET_MAIN_COMPANY_SELECTED = 'SET_MAIN_COMPANY_SELECTED';
export const SET_SUB_COMPANY_SELECTED_NAME = 'SET_SUB_COMPANY_SELECTED_NAME';
export const SET_SEARCH_COMPANY_SELECTED = 'SET_SEARCH_COMPANY_SELECTED';
export const SET_SHARE_URL = 'SET_SHARE_URL';
export const SET_ILLUSTRATION_URL = 'SET_ILLUSTRATION_URL';
export const SET_CURRENT_COLLECTION_ID = 'SET_CURRENT_COLLECTION_ID';
export const SET_COMMENT_SAVED ='SET_COMMENT_SAVED';
export const SET_DELETE_ROW ='SET_DELETE_ROW';
export const SET_EDIT_ROW ='SET_EDIT_ROW';
export const SET_SETTING_TEXT ='SET_SETTING_TEXT';
export const SET_RECORD ='SET_RECORD';
export const SET_ERRORS_ITEMS_LOADING ='SET_ERRORS_ITEMS_LOADING';
export const SET_ERRORS_ITEMS ='SET_ERRORS_ITEMS';
export const SET_CHART_ONE ='SET_CHART_ONE';
export const SET_TREE_TOGGLE ='SET_TREE_TOGGLE';
export const SET_TREE_EXPAND ='SET_TREE_EXPAND';
export const SET_ADD_YEARS ='SET_ADD_YEARS';
export const SET_TREE_COMPANY_SELECT ='SET_TREE_COMPANY_SELECT'; 
export const SET_ERRORS_ITEMS_APPEND ='SET_ERRORS_ITEMS_APPEND';

export const SET_CUR_TREE_LEVEL1 = 'SET_CUR_TREE_LEVEL1';
export const SET_CUR_TREE_LEVEL2 = 'SET_CUR_TREE_LEVEL2';
export const SET_CUR_TREE_LEVEL3 = 'SET_CUR_TREE_LEVEL3';
export const SET_CUR_TREE_LEVEL4 = 'SET_CUR_TREE_LEVEL4';
export const INIT_CUR_TREE_LEVEL1 = 'INIT_CUR_TREE_LEVEL1';
export const INIT_CUR_TREE_LEVEL2 = 'INIT_CUR_TREE_LEVEL2';
export const INIT_CUR_TREE_LEVEL3 = 'INIT_CUR_TREE_LEVEL3';
export const INIT_CUR_TREE_LEVEL4 = 'INIT_CUR_TREE_LEVEL4';

export const SET_ADMIN_USERS_LIST = 'SET_ADMIN_USERS_LIST';
export const SET_ADMIN_USERS_LIST_LOADING = 'SET_ADMIN_USERS_LIST_LOADING';
export const SET_ADMIN_EDIT_ROW = 'SET_ADMIN_EDIT_ROW';
export const SET_ADMIN_DELETE_ROW = 'SET_ADMIN_DELETE_ROW';
export const SET_COMPANY_REPORTS = 'SET_COMPANY_REPORTS';

export const SET_SEARCH_LAWFIRM_ID_LOADING = 'SET_SEARCH_LAWFIRM_ID_LOADING';
export const SET_SEARCH_LAWFIRM_ADDRESSES = 'SET_SEARCH_LAWFIRM_ADDRESSES';
export const SET_SEARCH_BY_ID_LAWFIRM_COMPANIES = 'SET_SEARCH_BY_ID_LAWFIRM_COMPANIES';
export const SET_SEARCH_ADDRESS_MODAL = 'SET_SEARCH_ADDRESS_MODAL';
export const SET_LAW_FIRM_ID = 'SET_LAW_FIRM_ID'; 


export const SET_COMPANY_ADDRESS_ID = 'SET_COMPANY_ADDRESS_ID'; 
export const SET_SEARCH_COMPANY_ADDRESS_MODAL = 'SET_SEARCH_COMPANY_ADDRESS_MODAL'; 
export const SET_ADD_COMPANY_TO_ACCOUNT_MODAL = 'SET_ADD_COMPANY_TO_ACCOUNT_MODAL'; 
export const SET_SEARCH_COMPANY_ADDRESSES = 'SET_SEARCH_COMPANY_ADDRESSES'; 
export const SET_SEARCH_COMPANY_ID_LOADING = 'SET_SEARCH_COMPANY_ID_LOADING'; 
export const SET_SEARCH_COMPANY_ID_ADDRESS = 'SET_SEARCH_COMPANY_ID_ADDRESS';
export const SET_SEARCH_MODAL_TYPE = 'SET_SEARCH_MODAL_TYPE';

export const TOGGLE_SHOW_3RD_PARTIES = 'TOGGLE_SHOW_3RD_PARTIES';
export const SET_ACCOUNT_USER_FORM = 'SET_ACCOUNT_USER_FORM';
export const SET_RECENT_TRANSACTIONS = 'SET_RECENT_TRANSACTIONS';
export const SET_CITED_LIST = 'SET_CITED_LIST';
export const SET_PARTIES_LIST = 'SET_PARTIES_LIST';
export const SET_CITED_PANEL_OPEN = 'SET_CITED_PANEL_OPEN';
export const SET_CITED_PARTIES_PANEL_OPEN = 'SET_CITED_PARTIES_PANEL_OPEN';
export const SET_GOOGLE_AUTH_TOKEN = 'SET_GOOGLE_AUTH_TOKEN';
export const SET_GOOGLE_PROFILE = 'SET_GOOGLE_PROFILE';
export const SET_TABLE_SCROLL_POSITION = 'SET_TABLE_SCROLL_POSITION';
export const SET_CITED_ASSIGNEE_IMAGE_RETREIVED = 'SET_CITED_ASSIGNEE_IMAGE_RETREIVED';
export const SET_ADD_COMPANY_TO_ACCOUNT_TYPE = 'SET_ADD_COMPANY_TO_ACCOUNT_TYPE';
export const SET_ADD_COMPANY_TO_ACCOUNT_REPRESENTATIVES = 'SET_ADD_COMPANY_TO_ACCOUNT_REPRESENTATIVES';
export const SET_ADD_COMPANY_TO_ACCOUNT_GROUP = 'SET_ADD_COMPANY_TO_ACCOUNT_GROUP';
export const SET_COMPANY_SCROLL_POS = 'SET_COMPANY_SCROLL_POS';
export const SET_REFRESH_RECLASSIFY = 'SET_REFRESH_RECLASSIFY';
export const SET_CITING_ASSIGNEE_LOADING = 'SET_CITING_ASSIGNEE_LOADING';
export const SET_PARTIES_LOADING = 'SET_PARTIES_LOADING';
export const SET_GROUP_MODAL = 'SET_GROUP_MODAL'; 
export const SET_ENTITY_REQUEST_FILENAME = 'SET_ENTITY_REQUEST_FILENAME'; 
