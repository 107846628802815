export default {
  auth: {
    auth_email_sent: false,
    isLoadingReset: true,
    password_reset: false,
    redirect_page: false,
  },
  patient: {
    ui:{
      showThirdParties: true,
      usptoMode: false,
    },
    google_auth_token: null,
    google_profile: null,   
    cited_panel: false,
    cited_parties_panel: false,
    cited_patents: { organizations:[], citedAssignees: [], totalRecords: 0 },
    cited_parties: { list: [], totalRecords: 0 },
    recentTransactions: [],
    account_user_form: false,
    addCompanyToAccountType: 0,
    addCompanyToAccountGroup: '',
    addCompanyToAccountRepresentatives: [],
    company_modal: 0,
    companyReports: [],
    currentWidget: 'settings',
    clientID: 0,
    company_scroll_pos: 0,
    clean_address_status: '',
    company_data: {}, 
    classificationKeyword: [],
    flag: 2,
    openAddAccountModal: false,
    searchedCompanyByIDLoading: true,
    searchedLawfirmAddressID: null,
	  searchedLawfirmAddressModal: false,
	  searchedLawfirmByIDLoading: true,
	  searchLawfirmByIDAddressData: [],
    searchLawfirmByIDAddresses: [],
    searchedCompanyAddressID: null,
    searchedCompanyAddressModal: false,
    searchCompanyIDAddressData: [],
    searchCompanyByIDAddresses: [], 
    inventorButtons: false,
    tableScrollPosition: 0,
    tree_file_name: '',
    refresh_reclassify: 0,
    searchBar: true, 
    singleSearchBar: false,
    loadingCitingAssignee: false,
    loadingParties: false,
    retreive_company_assets_holding: false,
    raw_assignment: false, 
    new_companies_request: [],  
    keywords: [],
    buttonsStatus: [],
    super_keywords: [],
    state_keywords: [],
    original_companies: [], 
    assignment_list: [],
    entities_list: [],
    entity_assets: [],
    transaction_list: {list:[], conveyance: [], update_conveyance: [], type: [], assignment_type: {}},
    lenders_list: [],
    law_firm_list: [], 
    lawyer_list: [],
    asset_list: [], 
    clientsData: [], 
    clientsLoading: false,
    inventorGroupModal: false,
    customersData: {
      'employee': [],  
      'ownership': [],
      'merger': [],
      'security': [],
      'other': []
    },
    searchHeight: '100%',
    treeHeight: '0%',
    corporate_html_file: '',   
    corporate_tree: [], 
    pdfFile: {document: '', form: '', agreement: ''},
    pdfTab: 0,
    pdfView: false,
    flag_update_text: '',
    treeForm: false,
    assets: {},
    assetsLoading: false,
    entites_update_message: '',
    settingText: 'Settings',
    record_item: null,
    customersLoading: false,
    treeCompanySelected: undefined,
    main_company_selected_name: '',
    searchCompaniesSelected: [],
    customersNamesCollections: [],
    customersNameCollectionsLoading: false,
    selectedRFID: '',
    customersRFIDAssets: [],
    customersRFIDAssetsLoading: false,
    lawyerList:[],
    lawyerListLoading: false,
    documentList: [],
    documentListLoading: false,
    update_document_row: null,
    childCompanies: [],
    companiesList: [],
    companyListLoading: false,
    portfolioList: [],
    userList: [],
    userListLoading: true,
    adminUserList: [],
    adminUserListLoading: true,
    admin_user_delete_row: false,
    admin_user_edit_row: false,
    recordItems: {},
    recordItemsLoading: false,
    treeToggle: [],
    treeExpand: [], 
    errorItems: {count:{uspto: 0, patent: 0}, list:{invent: [], assign: [], corr: [], address: [], security: []}},
    errorItemsLoading: false,
    searchCompanies: [],
    searchCompanyLoading: false,
    alertsCount: 0,
    messagesCount: 0,
    charts: {},
    chartsLoading: false,
    timeLine: {},
    timeLineLoading: false,
    transactions: {buy: 0, sale: 0, security: 0, release: 0, license_in: 0, license_out: 0},
    transactionsLoading: false,
    comments: '',
    commentsLoading: false,
    screenHeight: 0,
    screenWidth: 0,
    assetsOutsource: {},
    assetsOutsourceLoading: false,
    validateCounter: {application: 0, patent: 0, encumbered: 0},
    validateCounterLoading: false,
    assetsCount: {weekly_transactions: 0, weekly_applications: 0, monthly_transactions: 0, montly_applications: 0, quaterly_transactions: 0, quaterly_applications: 0},
    assetsCountLoading: false,
    main_company_selected: false,
    tree: {}, 
    company_tree: {},
    currentAsset: '',
    currentAssetType: '',
    commentMessage: '',
    siteLogo: {},
    image_retrieved_cited_assignee_id: 0,
    nestGridTab: 0,
    chartTab: 0,
    timelineTab: 0,
    fixitTab: 0,
    recorditTab: 0,
    settingTab: 0,
    chart_one: [],
    chart_two: [],
    chart_three: [],
    chart_four: [], 
    chart_five: [], 
    add_years: true,
    errorTotal: 0,
    illustrationUrl: 'about:blank',
    curTree: [
      {
        curTreeLevel1: '',
        curTreeLevel2: '',
        curTreeLevel3: '',
        curTreeLevel4: ''
      },
      {
        curTreeLevel1: '',
        curTreeLevel2: '',
        curTreeLevel3: '',
        curTreeLevel4: ''
      },
      {
        curTreeLevel1: '',
        curTreeLevel2: '',
        curTreeLevel3: '',
        curTreeLevel4: ''
      },
      {
        curTreeLevel1: '',
        curTreeLevel2: '',
        curTreeLevel3: '',
        curTreeLevel4: ''
      }
    ],
  }
};